var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"about"}},[_vm._m(0),_vm._v(" "),_c('p',{staticClass:"title"},[_vm._v("了解发B网")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"content2"},[_c('p',{staticClass:"title"},[_vm._v("联系我们")]),_vm._v(" "),_c('div',{staticClass:"map"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"text_wrap"},[_c('p',{staticClass:"title1"},[_vm._v("中山市天牛网络有限公司")]),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_11.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("客服ＱＱ："+_vm._s(_vm.$store.state.tenantInfo.qq))])]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_12.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("服务热线："+_vm._s(_vm.$store.state.tenantInfo.phone))])]),_vm._v(" "),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_img"},[_c('img',{attrs:{"src":require("@/assets/img/about_01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"line line1"}),_vm._v(" "),_c('div',{staticClass:"line line2"}),_vm._v(" "),_c('div',{staticClass:"line line3"}),_vm._v(" "),_c('div',{staticClass:"line line4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_list"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_02.png"),"alt":""}}),_vm._v("\n            3年运营经验\n        ")]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_03.png"),"alt":""}}),_vm._v("\n            服务客户超过30万\n        ")]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_04.png"),"alt":""}}),_vm._v("\n            省市地区覆盖过百\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('p',{staticClass:"title1"},[_vm._v("我们的优势")]),_vm._v(" "),_c('div',{staticClass:"img_list1"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_06.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("实时发货保障")]),_vm._v(" "),_c('p',[_vm._v("200名仓库打包人员随时恭候您的订单，来单就打，打了就发，发了就出物流信息")])]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_07.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("一键式操作")]),_vm._v(" "),_c('p',[_vm._v("百种礼品选择，简单快捷的操作流程，做您的贴身24小时新型仓储物流管家")])]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_08.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("保质保量")]),_vm._v(" "),_c('p',[_vm._v("礼品上精挑细选，心里装的都是商家和客户，让商家满意价格，让客户满意礼品是我们的宗旨")])]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_09.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("您的利润，我来提高")]),_vm._v(" "),_c('p',[_vm._v("选择我们，你的发货邮费成本迅速降低，将利润用到引流上，让您的店铺蒸蒸日上")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_img"},[_c('img',{attrs:{"src":require("@/assets/img/about_14.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_10.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("详细地址：广东省中山市小榄镇民安中路191号2楼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/about_13.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v("服务时间：周一至周日8:30-21:30")])])
}]

export { render, staticRenderFns }