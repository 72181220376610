<template>
    <div id="about">
        <div class="top_img"><img src="@/assets/img/about_01.png" alt=""></div>
        <p class="title">了解发B网</p>
        <div class="content">
            <div class="line line1"></div>     
            <div class="line line2"></div>     
            <div class="line line3"></div>     
            <div class="line line4"></div>     
           
        </div>
        <div class="img_list">
            <div>
                <img src="@/assets/img/about_02.png" alt="">
                3年运营经验
            </div>
            <div>
                <img src="@/assets/img/about_03.png" alt="">
                服务客户超过30万
            </div>
            <div>
                <img src="@/assets/img/about_04.png" alt="">
                省市地区覆盖过百
            </div>
        </div>
        <div class="bg">
            <p class="title1">我们的优势</p>
            <div class="img_list1">
                <div>
                    <img src="@/assets/img/about_06.png" alt="">
                    <p>实时发货保障</p> 
                    <p>200名仓库打包人员随时恭候您的订单，来单就打，打了就发，发了就出物流信息</p>
                </div>
                <div>
                    <img src="@/assets/img/about_07.png" alt="">
                    <p>一键式操作</p>
                    <p>百种礼品选择，简单快捷的操作流程，做您的贴身24小时新型仓储物流管家</p>
                </div>
                <div>
                    <img src="@/assets/img/about_08.png" alt="">
                    <p>保质保量</p>
                    <p>礼品上精挑细选，心里装的都是商家和客户，让商家满意价格，让客户满意礼品是我们的宗旨</p>
                </div>
                <div>
                    <img src="@/assets/img/about_09.png" alt="">
                    <p>您的利润，我来提高</p>
                    <p>选择我们，你的发货邮费成本迅速降低，将利润用到引流上，让您的店铺蒸蒸日上</p>
                </div>
            </div>
        </div>
        <div class="content2">
            <p class="title">联系我们</p>
            <div class="map">
                <div class="map_img">
                    <img src="@/assets/img/about_14.png" alt="">
                </div>
                <div class="text_wrap">
                    <p class="title1">中山市天牛网络有限公司</p>
                    <div>
                        <img src="@/assets/img/about_10.png" alt="">
                        <p>详细地址：广东省中山市小榄镇民安中路191号2楼</p>
                    </div>
                    <div>
                        <img src="@/assets/img/about_11.png" alt="">
                        <p>客服ＱＱ：{{$store.state.tenantInfo.qq}}</p>
                    </div>
                    <div>
                        <img src="@/assets/img/about_12.png" alt="">
                        <p>服务热线：{{$store.state.tenantInfo.phone}}</p>
                    </div>
                    <div>
                        <img src="@/assets/img/about_13.png" alt="">
                        <p>服务时间：周一至周日8:30-21:30</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#about{
    .top_img{
        width: 100%;
        img{
            width: 100%;
            display: block;
        }
    }
    .title{
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
    }
    .content{
        position: relative;
        font-size: 16px;
        color: #999999;
        text-align: center;
        width: 650px;
        margin:0 auto 50px;
        span{
            color: #FF8800;
            font-weight: bold;
        }
        .line{
            position: absolute;
            width: 30px;
            height: 30px;
        }
        .line1{
            top: -19px;
            left: -19px;
            border-top: 2px solid #f80;
            border-left: 2px solid #f80;
        }
        .line2{
            top: -19px;
            right: -19px;
            border-top: 2px solid #f80;
            border-right: 2px solid #f80;
        }
        .line3{
            bottom: -19px;
            left: -19px;
            border-bottom: 2px solid #f80;
            border-left: 2px solid #f80;
        }
        .line4{
            bottom: -19px;
            right: -19px;
            border-bottom: 2px solid #f80;
            border-right: 2px solid #f80;
        }
    }
    .img_list{
        text-align: center;
        div{
            display: inline-block;
            &:not(:first-child){
                margin-left: 90px;
            }
        }
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: #666666;

        img{
            height: 113px;
            display: block;
        }
    }
    .bg{
        margin-top: 70px;
        background: #F5F5F5;
        overflow: hidden;
        padding-bottom: 70px;
        .title1{
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-top: 70px;
            margin-bottom: 50px;
            text-align: center;
            opacity: 1;
        }
        .img_list1{
            text-align: center;
            div{
                display: inline-block;
                text-align: left;
                margin-left: 32px;
                img{
                    height: 110px;
                    display: block;
                    margin: auto;
                }
                p{
                    &:first-of-type{
                        text-align: center;
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #666666;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
                    &:last-of-type{
                        width: 224px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        color: #999999;
                    }
                }
            }
        }
    }
    .content2{
        overflow: hidden;
        padding-bottom: 70px;
        border: 5px solid #eee;
        .title{
            margin-top: 70px;
            margin-bottom: 50px;
        }
        .map{
            display: flex;
            justify-content: center;
            .map_img{
                width: 700px;
                img{
                    width: 100%;
                    display: block;
                }
            }
            .text_wrap{
                display: flex;
                flex-direction: column;
                border-top: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                border-bottom: 1px solid #EEEEEE;
                .title1{
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FF8800;
                    margin-top: 56px;
                    margin-bottom: 55px;
                    text-align: center;
                }
                div{
                    display: flex;
                    align-items: center;
                    flex: 1;
                    border-top: 1px solid #eee;
                    padding-left: 35px;
                    padding-right: 23px;
                    &:nth-child(even){
                        background: #EEEEEE;
                    }
                    img{
                        width: 30px;
                        margin-right: 10px;
                    }
                    p{
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
</style>