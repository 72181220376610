<template>
    <div id="about">
        <div class="admin_main_block">
            <div class="user_temp_title">{{article[0].title}}</div>
            <div class="admin_table_main">
                <div class="page_main" v-html="article[0].content">
                    <!-- <p>关关于{{$store.state.tenantInfo.company}}<span>天台赢鱼网络有限公司是一家电商软件及服务的科技公司，通过产品和服务，帮助全球电商商家更简单、更高效做生意。我们致力于成为电商领域里</span></p>
                    <p><span>值得信任和依赖的合作伙伴，帮助商家获得更大的成功。</span></p>
                    <p><span><br></span></p>
                    <p><span><br></span></p>
                    <p><span>公司地址：浙江省台州市天台县福溪街道下孟村1-7</span></p>
                    <p><span><br></span></p>
                    <p><span><br></span></p>
                    <p><span>电话：13665768848&nbsp;</span></p>
                    <p><span><br></span></p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            article:{
                title:'',
                conent:'',
            },
        }
    },
    created(){
        this.getArticleList();
    },
    mounted(){},
    watch: {},
    methods: {
        //获取最新一条关于我们
        getArticleList(){
            this.$request.post({
                 url:'/ReportApi/business/newlists',
                params:{
                    pageNo:1,
                    pageSize:1,
                    type:5,
                    isConent:1,
                },
                success: res => {
                   
                this.article = res[0];
                    
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#about{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        min-height: 855px;
        border-radius: 4px;
        padding: 20px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}
</style>